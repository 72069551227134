/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import {FavoriteContextProvider} from './src/favoriteContext.js'


// Self hosted fonts https://github.com/KyleAMathews/typefaces
require('typeface-charis-sil')
require('typeface-montserrat')
require('typeface-roboto-mono')
require('./src/styles/algolia.css')

export const wrapRootElement = ({ element }) => (
	<FavoriteContextProvider>{element}</FavoriteContextProvider>
)
