import React from "react"
import {getFavChartsFromStorage} from "./utils"

const defaultState = {
	charts: [],
	setCharts: ()=>{}
} // providing default state to be able to build with Gatsby

const FavoriteContext = React.createContext(defaultState);

export default FavoriteContext;

export const FavoriteContextProvider = ({children}) => {
	const [charts, setCharts] = React.useState(getFavChartsFromStorage());
	const contextValue = {
		charts,
		setCharts
	}
	return (
		<FavoriteContext.Provider value={contextValue}>
			{children}
		</FavoriteContext.Provider>
	)
}
