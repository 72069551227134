import { graphql } from "gatsby";


/**
 * removes leading and trailing slashes
 */
export const withoutSlashes = (fname) => fname.replace(/\//g, "");

/**
 * capitalizes first letter of each word in a sentence
 */
export const toTitleCase = (text) => text.toLowerCase()
  .split(' ')
  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

/**
 * removes root tag from HTML
 */
export const unwrapHTMLTag = text => text.replace(/<\/?p[^>]*>/g, "");

/**
 * converts text titles into slug
 */
export const slugify = text => text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');

export const iconsFragment = graphql`
  fragment iconList on Query {
    icons:allFile (filter: {
        relativePath : { regex:"/set/icon.svg/" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          publicURL
        }
      }
    }
  }
`;

export const postsFragment = graphql`
  fragment postList on Query {
      allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }, 
          filter: {fileAbsolutePath:{glob: "**/content/charts/**/index.md"}}
      ) {
        edges {
          node {
            fields{
              slug,
              variationOf
            }
            excerpt(pruneLength: 250)
            frontmatter {
              title
            }
          }
        }
      }
  }
`;

export function getPostsFromGraphQL(data, includeVariations, sort, dataNodesKey) {
  const postList = dataNodesKey ? data[dataNodesKey].edges : data.allMarkdownRemark.edges;
  const variations = postList.filter( ({ node }) => node.fields.variationOf);
  const mainCharts = postList.filter( ({ node }) => node.fields.variationOf === null);
  let thePosts = [];
  let theVariations = variations;

  const mapVariations = (variations) => variations.map ( ({ node })  => ({
    title: node.frontmatter.title,
    slug: node.fields.slug,
    excerpt: node.excerpt
  }));

  mainCharts.forEach(({ node }) => {
    const chartSlug = node.fields.slug;
    const thisVariations = theVariations.filter( ({ node }) => node.fields.variationOf === chartSlug);
    const chartVariations = mapVariations(thisVariations);

    const post = {
      title: node.frontmatter.title,
      slug: chartSlug,
      excerpt: node.excerpt,
      variations: chartVariations.length
    }
    thePosts.push(post);
    if (includeVariations && chartVariations.length > 0) {
      thePosts.push(...chartVariations);
      const remainingVariations = theVariations.filter( ({ node }) => node.fields.variationOf !== chartSlug);
      theVariations = remainingVariations;
    }
  });

  //TODO: come up with better processing of charts and variations, so no reshufling happens

  // patch to include variationsOnly, without main charts
  if (includeVariations && theVariations.length > 0) {
    const chartVariations = mapVariations(theVariations);
    thePosts.push(...chartVariations);
  }

  // sorting charts if necessary
  const byName = (a, b) => a.slug > b.slug ? 1 : a.slug < b.slug ? -1 : 0;
  const byNameReverse = (a, b) => a.slug < b.slug ? 1 : a.slug > b.slug ? -1 : 0
  if (sort &&sort.sortBy === "name") {
    thePosts = sort.sortOrder === "desc" ? thePosts.sort(byName) : thePosts.sort(byNameReverse)
  } else if (sort && sort.sortOrder === "asc") {
    thePosts = thePosts.reverse();
  }
  return thePosts;
}

export function getPostsFromGraphQLGroup(data, showVariations, tagGroupId) {
  const removeDuplicatesFromArray = (arr, uniqueProperty) => {
    const getNestedPropertyValue = (obj, path) => {
      const properties = path.split('.');
      return properties.reduce((acc, prop) => (acc ? acc[prop] : undefined), obj);
    };
    
    const uniqueObjects = [];
    const keysSet = new Set();
  
    for (const obj of arr) {
      const key = getNestedPropertyValue(obj, uniqueProperty);
      if (!keysSet.has(key)) {
        keysSet.add(key);
        uniqueObjects.push(obj);
      }
    }
  
    return uniqueObjects;
  };

  function getVariationsFromGroups(groupsList){
    var allVariations = []; //temp. buffer
    groupsList.forEach(function (group) {
      const uniqueChartVariations = removeDuplicatesFromArray(group.edges, "node.frontmatter.title");
      const variationsInGroup = uniqueChartVariations
        .filter( ({ node }) => node.fields.variationOf )
        .map( ({ node }) => ({
          slug: node.fields.slug,
          title:node.frontmatter.title,
          variationOf:node.fields.variationOf
        }));
      allVariations = [...allVariations, ...variationsInGroup];
    });
    // removing duplicates
    const uniqueAllVariations = allVariations.filter(function(item, pos) {
      return allVariations.findIndex(el => el.slug === item.slug) === pos;
    })
    return uniqueAllVariations;
  }
  const groupsList = data[tagGroupId].group;
  const allVariations = getVariationsFromGroups(groupsList);

  const groups = groupsList.map(function (group) {
    const uniqueCharts = removeDuplicatesFromArray(group.edges, "node.frontmatter.title");
    const allCharts = uniqueCharts;
    const withoutVariations = uniqueCharts.filter( ({ node }) => node.fields.variationOf === null);
    const chartsInGroup = showVariations ? allCharts : withoutVariations;
    return {
      name: group.fieldValue,
      charts: chartsInGroup.map(function({ node }) {
        const chart = {
          title: node.frontmatter.title,
          slug: node.fields.slug
        }
        if (showVariations) {
          chart.variationOf = node.fields.variationOf
        } else {
          chart.variations = allVariations.filter(el => el.variationOf === node.fields.slug).length
        }
        return chart;
      })
    }
  });
  return groups;
}

export const FAVCHARTS_STORAGE_KEY = 'favorite_charts';

export const getFavChartsFromStorage = () => JSON.parse(localStorage.getItem(FAVCHARTS_STORAGE_KEY)) || {};

export const saveFavChartsInStorage = (favCharts) => localStorage.setItem(FAVCHARTS_STORAGE_KEY, JSON.stringify(favCharts));
